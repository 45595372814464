import Vue from "vue";
import Vuex from "vuex";
import { mapMutations, memory } from "ngt-frontend-core";
import { getField, updateField } from "vuex-map-fields";

// try to get saved application settings from localStorage or set a default one
const appSettings = {};
const appSettingsDefaults = {
  gdprCookie: false,
  templates: {
    limit: {
      sortBy: ["name"],
      sortDesc: [false]
    }
  },
  messages: {
    limit: {
      sortBy: ["createdAt"],
      sortDesc: [true]
    }
  },
  activity: {
    limit: {
      sortBy: ["createdAt"],
      sortDesc: [true]
    }
  },
  notifications: {
    limit: {}
  }
};
memory.getItem(
  process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
  item => {
    if (item) {
      Object.assign(appSettings, item);
    } else {
      Object.assign(appSettings, appSettingsDefaults);
    }
  },
  function() {
    Object.assign(appSettings, appSettingsDefaults);
  }
);

Vue.use(Vuex);

const state = {
  loading: false,
  user: false,
  tags: false,
  hide_toolbar: false,
  open_settings_dialog: false,
  canInstallPWA: false,
  page: "",
  drawer: {
    open: false,
    clipped: true,
    mobileBreakPoint: 15000,
    fixed: false,
    permanent: false,
    mini: true
  },
  APIdata: {}, // for API response cache
  appSettings,
  /*** IMPORTANT BASE CONFIG ***/
  // base Report Manager API URL
  email_manager_api: process.env.VUE_APP_EMAIL_GATEWAY_API,
  // API URL for identities handling
  idp_api_url: process.env.VUE_APP_IDP_API,
  // API URL for idp registration
  idp_reg_url: process.env.VUE_APP_IDP_REGISTRATION,
  // API URL for forgotten password
  idp_pwd_url: process.env.VUE_APP_IDP_PASSWORD_RESET,
  // API URL for Login Manager
  idp_loginMan_url: process.env.VUE_APP_IDP_LOGIN_MANAGER
  /*** END OF IMPORTANT BASE CONFIG ***/
};

export default new Vuex.Store({
  state,
  mutations: {
    updateField,
    ...mapMutations(state),
    device(state, setVal) {
      state.device = { ...state.device, ...setVal };
    },
    drawer(state, setVal) {
      state.drawer = { ...state.drawer, ...setVal };
    },
    setAppSetting(state, valueObject) {
      if (Object.prototype.hasOwnProperty.call(valueObject, "parent")) {
        state.appSettings[valueObject.parent][valueObject.key] =
          valueObject.value;
      } else {
        state.appSettings[valueObject.key] = valueObject.value;
      }
      memory.setItem(
        process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
        state.appSettings
      );
    }
  },
  actions: {},
  getters: {
    getField,
    getTags: state => () => state.tags
  },
  modules: {}
});

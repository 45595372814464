import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true
  },
  theme: {
    themes: {
      light: {
        application: "#ffffff",
        primary: "#0090D4",
        secondary: "#FFC629",
        accent: "#8BD1CC",
        accent_dark: "#8BD1CC",
        darker: "#6f6f6f",
        error: "#FB4D3D",
        red: "#D07373",
        gray1: "#F0F3F5",
        gray2: "#5D6168",
        gray3: "#696A6F",
        gray4: "#E5E5E5",
        white: "#ffffff"
      }
    }
  }
});

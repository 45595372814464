import Vue from "vue";
import i18n from "@/locales/i18n";
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

Vue.mixin({
  methods: {
    errorSnackbar(error) {
      this.$store.state.snackbar = {
        text: error,
        color: "error",
        timeout: 10000,
        open: true
      };
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async checkJob(jobId) {
      return new Promise(resolve => {
        callAPI({
          url: `${this.$store.state.report_manager_api}/jobs/${jobId}`,
          method: "GET"
        })
          .then(response => {
            resolve(response.data.status.split(".").pop());
          })
          .catch(error => {
            this.$store.state.snackbar = {
              text: error,
              color: "error",
              timeout: 10000,
              open: true
            };
          });
      });
    },

    showAssets(assets) {
      const currentAssetsList = [];
      assets.map(item => {
        item.assetId &&
          item.meta
            .filter(v => ["both", "ui"].includes(v.display))
            .map(v => currentAssetsList.push(v.value));
        item.assetIds &&
          item.assetIds.map(item => {
            item.meta
              .filter(v => ["both", "ui"].includes(v.display))
              .map(v => currentAssetsList.push(v.value));
          });
      });
      return currentAssetsList.join(", ") || this.$t("all_assets");
    },

    /* helpers */
    async askForData(url) {
      try {
        // console.log("callAPI url: ", url)
        const result = await callAPI({ url, method: "GET", cache: true });
        // console.log("callAPI result", result)
        return result.data;
      } catch (err) {
        // console.log("callAPI error", err)
        return null;
      }
    },

    async getDatasourceName(datasourceId) {
      if (!datasourceId) return "";
      const url = `${this.$store.state.report_manager_api}/datasources/${datasourceId}`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    async getTemplateName(templateId) {
      if (!templateId) return "";
      const url = `${this.$store.state.report_manager_api}/templates/${templateId}`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    async getUserName(url) {
      if (!url) return "";
      url += `/attrs/name`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    async getTask(queueId) {
      if (!queueId) {
        return null;
      }
      try {
        const result = await callAPI({
          url: `${this.$store.state.report_manager_api}/queue/${queueId}`,
          method: "GET",
          cache: true
        });
        return result.data;
      } catch {
        return this.$t("no_task_found");
      }
    },

    getStatusColor(status) {
      let statusTail = "";
      status.includes(".")
        ? (statusTail = status.split(".").pop())
        : (statusTail = status);
      switch (statusTail) {
        case "success":
          return "green";
        case "failed":
          return "red";
        case "purged":
        case "deleted":
        case "inactive":
          return "grey";
        case "active":
          return "blue";
        case "processed":
          return "blue lighten-2";
        case "pending":
          return "orange";
        default:
          return "blue darken-4";
      }
    },

    showReportFormat(type) {
      switch (type) {
        case "pdf":
          return "mdi-file-pdf";
        case "xls":
        case "xlsx":
          return "mdi-file-document";
        default:
          return "mdi-file-code";
      }
    },

    createAPIStream(url, initialPage = 0, limit = 30) {
      function _stream(_url, _initialPage, _limit) {
        return new Promise(resolve => {
          callAPI({
            url: `${_url}${
              _url.includes("?") ? "&" : "?"
            }page=${_initialPage}&limit=${_limit}`,
            method: "GET",
            cache: true
          }).then(result => {
            _initialPage++;
            resolve({
              data: result.data,
              next() {
                return _stream(_url, _initialPage, _limit);
              }
            });
          });
        });
      }
      return () => _stream(url, initialPage, limit);
    },

    massDataLoader(storeHere, url, initialPage = 0, limit = 20) {
      const stream = this.createAPIStream(url, initialPage, limit);
      async function _take(_stream) {
        const { data, next } = await _stream();
        if (data.length === 0) {
          return;
        }
        data.forEach(item => {
          storeHere.push(item);
        });
        return _take(next);
      }
      return _take(stream);
    },

    // It takes an array of normal objects
    arrToHash(arr, key) {
      key = key || "_id";
      if (arr.length == 0) return {};
      if (typeof arr[0] !== "object") return {};
      const result = {};
      arr.forEach(p => (result[p[key]] = this.omit(p, key)));
      return result;
    },

    // Creates an array of normal objects
    hashToArr(hash, key) {
      key = key || "_id";
      const hashKeys = Object.keys(hash);
      if (hashKeys.length == 0) return [];
      if (typeof hash[hashKeys[0]] !== "object") return [];
      const result = hashKeys.map(p => ({ [key]: p, ...hash[p] }));
      return result;
    },

    //clone object except defined keys
    omit(obj, omitKey) {
      return Object.keys(obj).reduce((result, key) => {
        if (key !== omitKey) {
          result[key] = obj[key];
        }
        return result;
      }, {});
    },

    getDefaultLocale() {
      let locale = localStorage.getItem("locale");
      if (locale == "null") locale = null;
      if (!locale && this.$store && this.$store.state.user) {
        locale = this.$store.state.user.locale;
      }
      if (!locale) {
        locale = "en_US";
      }
      return locale;
    },

    getDefaultTimeZone() {
      let timezone = localStorage.getItem("timezone");
      if (timezone == "null") timezone = null;
      const user = this.$store.state.user;
      if (!timezone && this.$store && this.$store.state.user) {
        timezone = this.$store.state.user.timezone;
      }
      if (!timezone) {
        timezone = "GMT";
      }
      return timezone;
    }
  }
});

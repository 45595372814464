import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/locales/i18n";
import { LoginBff } from "ngt-frontend-core";
import { NoLicense } from "ngt-frontend-core";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    /* email log routes */
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/MessageLog"),
      meta: {
        title: () => i18n.t("message_log")
      }
    },
    {
      path: "/activity",
      name: "Activity Log",
      component: () => import("@/views/ActivityLog"),
      meta: {
        title: () => i18n.t("activity_log")
      }
    },
    /* admin routes */
    {
      path: "/admin/templates",
      name: "Manage Templates",
      component: () => import("@/views/Templates"),
      meta: {
        title: () => i18n.t("manage_templates")
      }
    },
    {
      path: "/admin/templates/:templateId",
      name: "Manage Template Details",
      component: () => import("@/views/TemplateDetails"),
      meta: {
        title: () => i18n.t("manage_template_details")
      }
    },
    {
      path: "/admin/files",
      name: "Manage Files",
      component: () => import("@/views/Files"),
      meta: {
        title: () => i18n.t("manage_files")
      }
    },
    /*service routes */
    {
      path: "/authentication",
      name: "Login Page",
      component: LoginBff,
      meta: {
        title: () => i18n.t("login")
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: () => import("@/views/Settings"),
      meta: {
        title: () => i18n.t("title_settings")
      }
    },
    {
      path: "/noLicense",
      name: "NoLicense",
      component: NoLicense,
      meta: {
        title: () => i18n.t("no_license")
      }
    }
  ]
});

// Helper function to prevent duplicate navigation error
router.push_safe = params => {
  let from = router.currentRoute.fullPath;
  let to = router.resolve(params).route.fullPath;
  if (from === to) return;

  router.push(params);
};

export default router;
